import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '../firebase'
import Firebase from 'firebase'
import router from '../router'
import { getRole } from '../API/authorization'
import { getMembers } from '../API/members'
import { getToken } from '../API/auth'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    overlayForm : true,
    snackbarConnection: false,
    user: null,
    isAuthorized: false,
    members: [],
    status: null,
    error: null,
    dialogViewItem: false,
    all_token : {
      token_type: null,
      access_token: null,
    },
    itemToView: {
      IdAction: null,
      id: null,
      titre: null,
      status: null,
      debrief: null,
      informations: null,
      dateDebut: null,
      dateFin: null,
      budget: null,
      sumTjm: null,
      type_action: null,
      a_renouveler: null,
      satisfaction: null,
      organisation: null,
      participants: null,
      candidats: null,
      candidats_qualifies: null,
      recrutes: null,
      ecoles: null,
      participants2: null,
      participants2_tjm: null,
      alumni: null,
      contactsEcole: null,
      mail: null,
      nom: null,
      prenom: null,
      presenceAction: null,
    },

  },
  mutations: {

    setUser(state, payload) {
      state.user = payload
    },

    removeUser(state) {
      state.user = null
    },

    setUserName(state, payload) {
      state.userName = payload
    },

    setUserFamillyName(state, payload) {
      state.FamillyName = payload
    },

    setUserEmail(state, payload) {
      state.userEmail = payload
    },

    setPhotoURL(state, payload) {
      state.photoURL = payload
    },

    setStatus(state, payload) {
      state.status = payload
    },

    setAuthorization(state, payload) {
      getRole(payload).then(response => {
        
        if (typeof response == "number") {
          state.isAuthorized = response;
        }
        else {
          state.isAuthorized = 0;
        }
      })
    },

    setGSuiteMembers(state) {
      getMembers((response) => {
        var regexp = process.env.NODE_ENV == 'development' ? /^[a-z-]+\.[a-z-]+@mc2i-groupe.eu$/ : /^[a-z-]+\.[a-z-]+@mc2i.fr$/ ;
        var regex2 = new RegExp(regexp);
        for (var i = 0; i < response.length; i++) {
          if (regex2.test(response[i].primaryEmail)) {
            state.members.push(response[i].primaryEmail)
          }
        }
        state.overlayForm = false;
      });
    },

    setError(state, payload) {
      state.error = payload
    },

    setDialogViewItem(state, payload) {
      state.dialogViewItem = payload;
    },

    setItemToView(state, payload) {
      state.itemToView = payload;
    },

    setSnackbarConnection(state, payload) {
      state.snackbarConnection = payload;
    },

    setOverlayForm(state, payload)
    {
      state.overlayForm = payload
    },

    setToken(state, payload)
    {
      state.all_token = payload
    },
  
  },
  actions: {


    signInAction({ commit }, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          router.push('/dashboard').catch(() => { })
          router.push('/admin').catch(() => { })
        })
        .catch((error) => {
          commit("setSnackbarConnection", true);
          commit('setStatus', 'failure')
          commit('setError', error.message)
        })
      getToken()
        .then(token => {
          commit('setToken', token)
          router.push('/').catch(() => { })
        })
    },

    googleSignInAction({ commit }) {

      const provider = new Firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        hd: process.env.NODE_ENV == 'development' ? "mc2i.fr" : "mc2i.fr"
      });
      provider.addScope('profile');
      provider.addScope('email');
      provider.addScope('https://www.googleapis.com/auth/admin.directory.group.readonly');


      firebase.auth().signInWithPopup(provider)
        .then((response) => {
          commit('setUser', response)
          commit('setStatus', 'success')
          commit('setError', null)
          commit('setPhotoURL', response.user.photoURL)
          commit('setUserName', response.additionalUserInfo.profile.given_name)
          commit('setUserFamillyName', response.additionalUserInfo.profile.family_name)
          commit('setUserEmail', response.additionalUserInfo.profile.email)
          commit('setAuthorization', response.additionalUserInfo.profile.email)
          commit('setGSuiteMembers')
          router.push('/dashboard').catch(() => { })
          router.push('/admin').catch(() => { })

        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
        })

      getToken()
        .then(token => {
          commit('setToken', token)
          router.push('/').catch(() => { })
        })
    },

    signOutAction({ commit }) {
      firebase.auth().signOut()
        .then(() => {
          commit('setUser', null)
          commit('setStatus', 'success')
          commit('setError', null)
          router.push('/login').catch(() => { })
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
        })
    }
  },

  getters: {
    overlayForm(state)
    {
      return state.overlayForm
    },

    status(state) {
      return state.status
    },

    user(state) {
      return state.user
    },

    avatar(state) {
      return state.photoURL
    },

    given_name(state) {
      return state.userName
    },

    family_name(state) {
      return state.FamillyName
    },

    email(state) {
      return state.userEmail
    },

    authorization(state) {
      return state.isAuthorized;
    },

    gsuiteMembers(state) {
      return state.members;
    },

    error(state) {
      return state.error
    },

    dialogViewItem(state) {
      return state.dialogViewItem
    },

    itemToView(state) {
      return state.itemToView
    },

    snackbarConnection(state) {
      return state.snackbarConnection
    },

    all_token(state){
      return state.all_token
    }
  }
})
