import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function getEmail() {
    let tab = [];
    let options = {
        headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    return axios.get(`${baseUrl}/` + "emails", options)
        .then((response) => {
            for (let i = 0; i < response.data.length; i++){
                tab[i] = response.data[i].email;
            }
            return tab;
        })
        .catch(function (error) {
            return error
        })
}