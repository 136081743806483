import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function getIntitule(tab_api) {
    let tab = [];
    let options = {
        headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    return axios.get(`${baseUrl}/` + tab_api, options)
        .then((response) => {
            if(tab_api=="ref_actions"){
                for (let i = 0; i < response.data.length; i++){
                    tab[i] = response.data[i].label_rf_action;
                   
                }
                return tab;
            } 
            if(tab_api=="type_action"||tab_api=="type_ecole"){
                for (let i = 0; i < response.data.length; i++){
                    tab[i] = response.data[i].Intitule;
                   
                }
                return tab;
            } 
            if(tab_api=="ecoles"){
                for (let i = 0; i < response.data.length; i++){
                    tab[i] = response.data[i].Intitule;
                   
                }
                return tab;
            } 
            
            
            
        })
        .catch(function (error) {
            return error
        })
}