import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Form from '@/components/Form.vue'
import Dashboard from '@/components/Dashboard.vue'
import Admin from '@/components/Admin.vue'
import Debrief from '@/components/Debrief'
import store from '../store'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Form,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/admin',
      component: Admin,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/debrief',
      component: Debrief,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})


// do some security stuff
router.beforeEach((to, from, next) => {
  // Check if the guard is called in the meta object
  if (to.matched.some(record => record.meta.requireAuth)) {
    // it means that the page must be protected by an auth
    if (!store.getters.user) {
      // not logged in 
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
    else if (!store.getters.authorization && to.path != '/' && to.path != '/debrief') {
      next({
        path: '/'
      })
    }
    else {
      next()
    }
  } else {
    next()
  }
})

export default router;