<template >
  <v-dialog v-model="dialogViewItem" max-width="90%">
    <v-container grid-list-md text-xs-center style="background-color : white">
      <v-card-title>
        <v-row justify="space-around" align="center">
          <v-col cols="12" sm="2" md="4">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <div style="font-family:lato; font-size:25px; text-align:center">{{itemToView.titre}}</div>
          </v-col>
          <v-col cols="12" sm="2" md="4">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card color="#FAFAFA">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-card-title
                  class="title"
                  style="font-family:lato; background-color: #006a9e; color : white"
                >
                  <v-icon style="color:white" left>info</v-icon>Détails de l'action
                </v-card-title>
              </v-col>
              <v-card-text>
                <v-row justify="space-around">
                  <v-col cols="11" sm="11" md="6">
                    <v-text-field
                      v-model="itemToView.type_action"
                      label="Type d'action"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-text-field v-model="itemToView.status" label="Status" outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6" v-if="authorization == 2">
                    <v-text-field
                      v-model="itemToView.sum_tjm"
                      label="TJM"
                      persistent-hint
                      :hint="itemToView.participants2_tjm"
                      outlined
                      readonly
                      @click:append="refreshTjm"
                      append-icon="refresh"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-text-field v-model="itemToView.budget" label="Budget" outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-text-field
                      v-model="itemToView.dateDebut"
                      label="Date de début"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-text-field
                      v-model="itemToView.dateFin"
                      label="Date de fin"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-combobox
                      v-model="ecoleArray"
                      chips
                      small-chips
                      label="Ecoles associées"
                      multiple
                      readonly
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip :color="'#dd0061'" :text-color="'white'" label small>
                          <span class="pr-2" style="font-family:lato">{{ item }}</span>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-combobox
                      v-model="participantsArray"
                      small-chips
                      label="Participants mc2i"
                      multiple
                      readonly
                    >
                      <template v-slot:selection="{item }">
                        <v-chip :color="'#dd0061'" :text-color="'white'" label small>
                          <span class="pr-2" style="font-family:lato">{{ item }}</span>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-textarea
                      v-model="itemToView.contact_ecole"
                      label="Contact(s) au sein de l'école"
                      outlined
                      readonly
                    ></v-textarea>
                  </v-col>
                  <v-col cols="11" sm="11" md="6">
                    <v-textarea
                      v-model="itemToView.informations"
                      label="Informations complémentaires"
                      outlined
                      readonly
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card color="#FAFAFA">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-card-title
                  class="title"
                  style="font-family:lato; background-color: #006a9e; color : white"
                >
                  <v-icon style="color:white" left>perm_contact_calendar</v-icon>Informations du collaborateur qui a déclaré l'action
                </v-card-title>
              </v-col>

              <v-card-text>
                <v-row justify="space-around">
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field v-model="itemToView.prenom" label="Prénom" outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field v-model="itemToView.nom" label="Nom" outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field v-model="itemToView.email" label="Mail" outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="11" md="5">
                    <v-text-field
                      v-model="itemToView.is_present"
                      label="Présence lors de l'action souhaitée"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-card outlined color="white">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card-title
              class="title"
              style="font-family:lato; background-color: #006a9e; color : white"
            >
              <v-icon style="color:white" left>question_answer</v-icon>Debrief
            </v-card-title>
          </v-col>
          <v-col cols="11">
            <v-card color="#FAFAFA">
              <v-card-subtitle
                style="font-family:lato; background-color: #164667; color : white"
              >A remplir par le consultant</v-card-subtitle>

              <v-card-text style="margin-top : 1%">
                <v-row justify="space-around">
                  <v-col cols="12" sm="11" md="4">
                    <v-text-field
                      v-model="itemToView.nb_participants"
                      label="Nombre de participants"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="11" md="4">
                    <v-text-field
                      v-model="itemToView.to_renew"
                      label="Renouvellement de cette action"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="11" md="4">
                    <v-text-field
                      v-model="itemToView.cout_additionnel"
                      label="Cout additionnel"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="11" md="4">
                    <v-textarea v-model="itemToView.feedback" label="Feedback" outlined readonly></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="11" md="4">
                    <v-textarea
                      v-model="itemToView.commentaire"
                      label="Commentaire"
                      outlined
                      readonly
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="11">
            <v-card color="#FAFAFA">
              <v-card-subtitle
                style="font-family:lato; background-color: #164667; color : white"
              >A remplir par les RH</v-card-subtitle>

              <v-row justify="space-around" style="margin:1%">
                <v-col cols="12" sm="12" md="4">
                  <v-card outlined color="#FAFAFA">
                    <v-card-subtitle class="title">Ecole de commerce</v-card-subtitle>
                    <v-row align="center" justify="center">
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recup_com"
                          label="Nombre de CV récupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_p1_com"
                          label="Nombre de CV P1 recupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recru_com"
                          label="Nombre de CV recrutés"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-card outlined color="#FAFAFA">
                    <v-card-subtitle class="title">Ecole d'ingénieurs</v-card-subtitle>
                    <v-row align="center" justify="center">
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recup_ing"
                          label="Nombre de CV récupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_p1_ing"
                          label="Nombre de CV P1 recupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recru_ing"
                          label="Nombre de CV recrutés"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-card outlined color="#FAFAFA">
                    <v-card-subtitle class="title">Université</v-card-subtitle>
                    <v-row align="center" justify="center">
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recup_uni"
                          label="Nombre de CV récupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_p1_uni"
                          label="Nombre de CV P1 recupérés suite à l'intervention"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="itemToView.nb_cv_recru_uni"
                          label="Nombre de CV recrutés"
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-card-actions>
        <v-row align="center" justify="center">
          <v-btn color="#006a9eff" rounded x-large style="color:white" v-on:click="close">
            <v-icon left>check</v-icon>OK
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-dialog>
</template>

<script>
import { getTjm } from "../API/GET/getTjm";
import { putTjm } from "../API/PUT/putTjm";
export default {

  data() {
    return {
      
    };
  },

  computed: {
    dialogViewItem: {
      get: function () {
        return this.$store.getters.dialogViewItem;
      },

      set: function (newVal) {
        this.$store.commit("setDialogViewItem", newVal);
      },
    },
    itemToView: {
      get: function () {
        return this.$store.getters.itemToView;
      },

      set: function (newVal) {
        this.$store.commit("setItemToView", newVal);
      },
    },
    ecoleArray: {
      get: function () {
        if (
          this.itemToView.ecoles !== undefined &&
          typeof this.itemToView.ecoles === "string"
        ) {
          var array = this.itemToView.ecoles.split(",");
          return array;
        }

        return this.itemToView.ecoles;
      },
      set: function (ecole) {
        this.itemToView.ecoles = ecole;
      },
    },
    participantsArray: {
      get: function () {
        if(this.itemToView.participants2 != null){
        if (
          this.itemToView.participants2 !== undefined &&
          typeof this.itemToView.participants2 === "string"
        ) {
          var array = this.itemToView.participants2.split(",");
          return array;
        } else if (this.itemToView.participants2) {
          var arr = [];
          for (var i = 0; i < this.itemToView.participants2.length; i++) {
            this.itemToView.participants2[i].email
              ? arr.push(this.itemToView.participants2[i].email)
              : null;
          }
          return arr.length > 0 ? arr : this.itemToView.participants2;
        }
        return this.itemToView.participants2;
        }
        var emptyArray = []
        return emptyArray
      },
      set: function (participants2) {
        this.itemToView.participants2 = participants2;
      },
    },
    authorization: {
      get: function () {
        return this.$store.getters.authorization;
      },
    },
  },

  methods: {
    
    close() {
      this.$store.commit("setDialogViewItem", false);
    },
    async refreshTjm(){
    
      await putTjm(this.itemToView)
        .then(() => {
          
        })
        .catch((error) => {
          return error;      
        });

      
      getTjm(this.itemToView.IdAction)
        .then((tab) => {
          this.itemToView.sumT_tjm = tab[0];
          this.itemToView.participants2_tjm = tab[1]
        })
        .catch((error) => {
          return error;      
        });
      
    },
  },

};
</script>