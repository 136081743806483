import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function getRefEcole() {
    let tab = [];
    let options = {
        headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    return axios.get(`${baseUrl}/ecoles`, options)
        .then((response) => {
            tab[0] = response.data;   
            return tab;
        })
        .catch(function (error) {
            return error
        })
}



