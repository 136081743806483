import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DatetimePicker from 'vuetify-datetime-picker'
import '@mdi/font/css/materialdesignicons.css' 
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

 
Vue.use(DatetimePicker)
Vue.use(Vuetify);
Vue.use(VueTelInput);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
});
