<template>
  <v-container class="fill-height" fluid style="background-color : rgba(0,106,158,0.1)">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
            <v-card-title
              style="font-family:lato; background-color: #006a9e; color : white; display:block;text-align: center;"
            >Connexion</v-card-title>
          <v-card-text >
            <v-form>
             <v-row align="center" justify="center" style="margin: 20px ;position:relative">
          <v-btn v-on:click="googleAuthLogin" outlined class="ma-2" title color="#006a9e" style="padding-right:8%;padding-left:8%;">
           <strong> Se connecter avec Google</strong>
            <v-icon right dark>mdi-google</v-icon>
          </v-btn>
        </v-row> 

            </v-form>
          </v-card-text>
        </v-card>
        
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbarConnection" color="red">
      Identifiants incorrects
      <v-btn color="white" text @click="snackbarConnection = false">Fermer</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {

  props: {
    source: String
  },
  data: () => ({
    passwordShow: false,
    valid: true,
    email: "",
    password: "",
    passwordRules: [v => !!v || "Veuillez renseigner votre mot de passe"]
  }),
  methods: {
    

    googleAuthLogin() {
      this.$store.dispatch("googleSignInAction");
    }
  },
  computed: {
    snackbarConnection: {
      get: function() {
        return this.$store.getters.snackbarConnection;
      },

      set: function(newVal) {
        this.$store.commit('setSnackbarConnection', newVal);
      }
    }
  }
};
</script>