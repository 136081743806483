const jwt = require('jsonwebtoken');
//const keys = require('./jwt.keys.json');
const fetch = require("node-fetch");

const createJwt = (projectId, algorithm) => {
  // Create a JWT to authenticate this device. The device will be disconnected
  // after the token expires, and will have to reconnect with a new token. The
  // audience field should always be set to the GCP project id.
  const token = {
    iss: process.env.VUE_APP_GCP_CLIENTEMAIL,
    scope: "https://www.googleapis.com/auth/admin.directory.user",
    iat: parseInt(Date.now() / 1000),
    exp: parseInt(Date.now() / 1000) + 60 * 60, // 20 minutes
    aud: "https://oauth2.googleapis.com/token",
    sub: process.env.VUE_APP_GCP_SUB
  };
  const privateKey = process.env.VUE_APP_GCP_PRIVATEKEY;
  return jwt.sign(token, privateKey, { algorithm: algorithm });

};

function getOauthBearer(token, callback) {
  
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: "grant_type=urn%3Aietf%3Aparams%3Aoauth%3Agrant-type%3Ajwt-bearer&assertion=" + token
  }

  const BASE_URL = 'https://oauth2.googleapis.com/token'
  fetch(BASE_URL, options)
    .then(response => response.json())
    .then(json => {
      callback(json.access_token);
    })
    .catch(err => console.error(err))
}

export function getMembers(callback) {
  let token = createJwt(process.env.VUE_APP_GCP_PROJECTID, 'RS256');
  getOauthBearer(token, body => {
    var url = "https://www.googleapis.com/admin/directory/v1/users?domain=" + process.env.VUE_APP_DOMAIN + "&maxResults=500"
    const headers = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + body
      }
    };
    var users = []
    var fetchNow = function () {
      fetch(url, headers).then(response => response.json()).then(json => {
        users = users.concat(json.users)    
        var nextPageToken = json.nextPageToken ? json.nextPageToken : null
        if (nextPageToken) {
          url = "https://www.googleapis.com/admin/directory/v1/users?domain=" + process.env.VUE_APP_DOMAIN + "&maxResults=500&pageToken=" + nextPageToken
          fetchNow();
        }
        else{
          callback(users)
        }
      });
    }
    fetchNow();
  });
}




