<template>
    <v-footer padless>
      <v-col style="font-family : Lato; color : black" class="text-center grey lighten-3 " cols="312">
        {{ new Date().getFullYear() }} - mc2i - Developed by DNI
      </v-col>
    </v-footer>
</template>
<script>
export default {
    
}
</script>