<template >
  <div style="background-color : rgba(0,106,158,0.1)" class="fill-height" fluid>
    <v-overlay :value="overlayForm" >
      <v-progress-circular indeterminate  size="150" width="10" ></v-progress-circular>
    </v-overlay>
    <h1
      style="text-align:center; margin-bottom:20px;font-family:lato; color: #006a9e"
    ><br>MC2I - DÉCLARATION D’ACTION CAMPUS</h1>
    <v-form ref="form" v-model="valid" >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="6">
          <v-card outlined elevation="5" color="#FAFAFA" style="margin-bottom : 5%" >
            <v-card-title
              style="font-family:lato; background-color: #006a9e; color : white;"
            >Détails de l'action</v-card-title>
            <v-card-text >
              <v-row justify="center">
              
                <v-col cols="12" sm="12" md="6" >
                
                  <v-text-field  
                    v-model="createdItem.titre"
                    label="Titre de l'action (*)"
                    :rules="regleTitreAction"
                    outlined
                    clearable
                    required
                    style="margin-top : 8%"
                    
                      
                  ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="createdItem.type_action"
                    :items="listTypeAction"
                    :rules="regleTypeAction"
                    label="Type d'action (*)"
                    item-text="type_action"
                    item-value="type_action"
                    required
                    outlined
                    style="margin-top : 8%"
                  ></v-autocomplete>
                </v-col>
              
              
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="createdItem.ecole"
                    :items="ecolesDispo"
                    chips
                    small-chips
                    label="Ecole(s) (*)"
                    :rules="regleEcole"
                    required
                    multiple
                    :search-input.sync="searchEcoles"
                    @change="searchEcoles = ''"
                    outlined
                  >
                    <template v-slot:selection="{ item, parent }">
                      <v-chip label small>
                        <span class="pr-2" style="font-family:lato">{{ item }}</span>
                        <v-icon small @click="parent.selectItem(item)">close</v-icon>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                
                <v-col cols="12" sm="12" md="6" >
                
               
                  <v-dialog
      v-model="calenderOn" width="350"
    >
      <template v-slot:activator="{ attrs }">
      
                 <v-text-field
                    v-model="createdItem.dateDebut"
                    label="Date de début (*)"
                    :date-picker-props="dateDebutProps"
                    :rules="regleDateDebut"
                    v-on:click="calenderOn=true"
                    prepend-icon="mdi-calendar"
                     v-bind="attrs"
        
                    
                    
                  ></v-text-field>
                  
        
      </template>

       <v-date-picker color="#dd0061"
                    label="Date de début (*)"
                    v-model="createdItem.dateDebut"
                    dateFormat="yyyy/MM/dd"
                     >
                      <template slot="dateIcon" >
                      <v-icon color="#006a9eff">mdi-calendar</v-icon>
                    </template>
                     <v-spacer></v-spacer>
                   <v-btn 
                     v-on:click="calenderOn=false"
                    color="#dd0061" 
                    style="color:white"
                  >
                  <v-icon left>check</v-icon>Valider 
                  </v-btn>
                  
                  </v-date-picker>
                  
                
    </v-dialog>
                 
                </v-col>
                
                <v-col cols="12" sm="12" md="6">
                   <v-dialog
      v-model="calenderOnFin" width="350"
    >
      <template v-slot:activator="{ attrs }">
      
                 <v-text-field
                    v-model="createdItem.dateFin"
                    label="Date de fin (*)"
                    :date-picker-props="dateFinProps"
                    :rules="regleDateFin"
                    v-on:click="calenderOnFin=true"
                    prepend-icon="mdi-calendar"
                     v-bind="attrs"
        
                    
                    
                  ></v-text-field>
                  
        
      </template>

       <v-date-picker color="#dd0061"
                    label="Date de fin (*)"
                    v-model="createdItem.dateFin"
                    dateFormat="yyyy/MM/dd"
                     >
                      <template slot="dateIcon" >
                      <v-icon color="#006a9eff">mdi-calendar</v-icon>
                    </template>
                     <v-spacer></v-spacer>
                   <v-btn 
                     v-on:click="calenderOnFin=false"
                    color="#dd0061" 
                    style="color:white"
                  >
                  <v-icon left>check</v-icon>Valider 
                  </v-btn>
                  
                  </v-date-picker>
                  
                
    </v-dialog>
                   
               
               
                </v-col>
                

                <v-col cols="12" sm="12">
                  <v-text-field color="#006a9e"
                  v-model="createdItem.budget" type="number" label="Budget" prefix="€" clearable  outlined></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" >
                
                  <v-switch color="#006a9e"
                    v-model="createdItem.is_present"
                    label="Souhaitez vous être présent lors de cette action ?"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="createdItem.contact_ecole" rows="2" clearable outlined>
                    <template v-slot:label>
                      <div>Contact(s) au sein de l'école</div>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
          
         <v-col cols="12" align="center" justify="center">
          <v-col sm="12" md="6">
          
            <v-card elevation="5" outlined color="#FAFAFA " >
              <v-card-title
                style="font-family:lato; background-color: #006a9e; color : white "
              >Participants</v-card-title>
              <v-card-text style="padding: 30px;">
                <v-autocomplete
                  v-model="createdItem.collabImplique"
                  :items="members"
                  chips
                  small-chips
                  label="Participants (*) (pensez à vous ajouter si vous participez à l'action)"
                  :rules="regleCollabImplique"
                  multiple
                  :search-input.sync="searchParticipants"
                  @change="searchParticipants = ''"
                  outlined
                >
                  <template v-slot:selection="{ item, parent }">
                    <v-chip label small>
                      <span class="pr-2" style="font-family:lato">{{ item }}</span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                  </template>
                </v-autocomplete>

                <v-textarea v-model="createdItem.infoCompl" rows="2" clearable outlined>
                  <template v-slot:label>
                    <div>Informations complémentaires</div>
                  </template>
                </v-textarea>
              </v-card-text>
            </v-card>
            
          </v-col>
          </v-col>
         
                 
      </v-row>

      <v-row justify="center">
        <v-btn
          class="mr-4"
          :disabled="!valid"
          rounded
          color="#006a9e"
          style="color:white; margin-bottom:3% ;margin-top:3%;padding-left:3% ;padding-right:3%"
          @click="validate"
          
        >
          <v-icon left>check</v-icon>Valider
        </v-btn>
      </v-row>
      <v-snackbar v-model="snackbar" color="#006a9e">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false">Fermer</v-btn>
      </v-snackbar>
      <v-dialog v-model="dialog" max-width="350">
        <v-card>
          <v-card-title style="font-family:lato">Erreur</v-card-title>

          <v-card-text
            style="font-family:lato"
          >Merci de renseigner la date de début et la date de fin de l'action</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#dd0061" rounded style="color:white" @click="dialog = false">
              <v-icon left>check</v-icon>OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>

import { getIntitule } from "../API/GET/getIntitule";
import { getEmail } from "../API/GET/getEmail";
import { postActionForm } from "../API/POST/postActionForm";

export default {
  components: {},
  data: () => ({
    alumni: "false",
    searchEcoles: "",
    searchParticipants: "",
    members: [],
    snackbar: false,
    dialog: false,
    listTypeAction: [],
    valid: true,
    dateDebutProps: {
      locale: "fr",
      color: "#006a9eff",
    },
   
    dateFinProps: {
      locale: "fr",
      color: "#dd0061",
    },
   
    prenom: null,
    reglePrenom: [(v) => !!v || "Veuillez renseigner votre prénom"],
    nom: null,
    regleNom: [(v) => !!v || "Veuillez renseigner votre nom"],
    email: null,
    regleEmail: [
      (v) => !!v || "Veuillez renseigner votre adresse mail",
      (v) => /.+@.+\..+/.test(v) || "Votre adresse mail doit être valide",
    ],
    titreAction: null,
    regleTitreAction: [
      (v) => !!v || "Veuillez renseigner le titre de l'action",
    ],
    typeAction: null,
    regleTypeAction: [(v) => !!v || "Veuillez renseigner le type d'action"],
    ecole: null,
    regleEcole: [
      (v) => !!v || "Veuillez renseigner le/les école(s) associée(s)",
    ],
    dateDebut: "",
    dateFin: "",
    dateDebut1: "",
    dateFin1: "",
    calenderOn:false,
    calenderOnFin:false,
    regleDateDebut: [(v) => !!v || "Veuillez renseigner la date"],
    regleDateFin: [(v) => !!v || "Veuillez renseigner la date"],
    budget: null,
    infoCompl: null,
    collabImplique: null,
    regleCollabImplique: [(v) => !!v || "Veuillez renseigner les collaborateurs impliqués"],
    contactsEcole: null,
    presenceAction: false,
    ecolesDispo:[],
    IdAction: null,
    maxIdAction: null,
    nbIdAction:null,
    createdItem: {},
    text:
      "Merci d'avoir renseigné une action. L'équipe Campus reviendra prochainement vers toi.",
  }),


  computed: {
    computedDateFormatted () {
        return this.formatDate(this.date)
      },
    
    overlayForm: {
      get: function () {
        return this.$store.getters.overlayForm
      },
      set : function(boolOverlay) {
        this.$store.commit("setOverlayForm", boolOverlay);
      }
    },
    
    
  },

  methods: {

    async validate() {

      if (
        this.$refs.form.validate() &&
        this.formatDate(this.createdItem.dateDebut) &&
        this.formatDate(this.createdItem.dateFin)
      ){
        
        this.createdItem.dateDebut1 = this.formatDate(this.createdItem.dateDebut);
        this.createdItem.dateFin1 = this.formatDate(this.createdItem.dateFin);
        this.createdItem.IdRef=null;
        await postActionForm(this.createdItem)
          .then(() => {
          })
          .catch((error) => {
            return error;      
          });

        this.snackbar = true;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } else {
        this.dialog = true;
      }
      this.createdItem.prenom = this.$store.getters.given_name;
      this.createdItem.nom = this.$store.getters.family_name;
      this.createdItem.email = this.$store.getters.email;
    },

    formatDate(date) {
      let newDate = new Date(date);
      let dd =
        newDate.getDate() < 10
          ? "0" + newDate.getDate().toString()
          : newDate.getDate().toString();
      let mm =
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1).toString()
          : (newDate.getMonth() + 1).toString();
      let yyyy = newDate.getFullYear();
      if (
        dd != "NaN" &&
        mm != "Nan" &&
        yyyy != "NaN" 
        
      ) {
        let formatDate = yyyy + "/" + mm + "/" + dd;
        if (formatDate != "1970/01/01") {
          return formatDate;
        }
      }
      return null;
    },

    compare(a, b) {
      if (a.groupe < b.groupe) {
        return -1;
      }
      if (a.groupe > b.groupe) {
        return 1;
      }
      return 0;
    },
  },

  created() {
        
        
        
        

        getIntitule("ref_actions")
            .then((tab) => {
              this.listTypeAction = tab;
            })
            .catch((error) => {
              return error;      
        });


        getIntitule("ecoles")
            .then((tab) => {
              this.ecolesDispo = tab;
            })
            .catch((error) => {
              return error;      
        });

        getEmail()
            .then((tab) => {
              this.members = tab;
            })
            .catch((error) => {
              return error;      
        });
  },
  beforeMount() {
    this.createdItem.prenom = this.$store.getters.given_name;
    this.createdItem.nom = this.$store.getters.family_name;
    this.createdItem.email = this.$store.getters.email;
    this.members = this.$store.getters.gsuiteMembers;
  },
  
 
};

</script>