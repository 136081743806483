import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export async function postActionForm(createdItem) {

    let options = {
        headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };

    await axios.post(`${baseUrl}/actions`,{
        IdAction : null,
        presenceAction: createdItem.is_present ? createdItem.is_present : false,
        typeAction: createdItem.type_action ,
        alumni: createdItem.alumni ? createdItem.alumni : false,
        candidats: null,
        candidats_qualifies: null, 
        organisation: null,
        recrutes: null,
        satisfaction: null,
        debrief: null,
        prenom: createdItem.prenom,
        nom: createdItem.nom,
        mail: createdItem.email,
        titre: createdItem.titre,
        status:
          store.getters.authorization == 1 ||
          store.getters.authorization == 2
            ? "A valider"
            : "Validée",
        informations: createdItem.infoCompl ? createdItem.infoCompl : null,
        budget: createdItem.budget ? createdItem.budget : null,
        sumTjm: null,
        dateDebut: createdItem.dateDebut1,
        dateFin: createdItem.dateFin1,
        a_renouveler: null,
        cout_additionnel: null,
        participants: null,
        feedback: null,
        commentaire: null,
        cv_recuperes_commerce: null,
        cv_p1_commerce: null,
        cv_recrutes_commerce: null,
        cv_recuperes_ingenieurs: null,
        cv_p1_ingenieurs: null,
        cv_recrutes_ingenieurs: null,
        cv_recuperes_universite: null,
        cv_p1_universite: null,
        cv_recrutes_universite: null,
        contactsEcole: createdItem.contact_ecole ? createdItem.contact_ecole : null,
        ecoles: createdItem.ecole ? createdItem.ecole : null,
        participants2: createdItem.collabImplique ? createdItem.collabImplique : null,
    }, options)       
    .then((response) => {
        var parse_answer_ecole = JSON.parse(response.config.data);
        createdItem.ecole = parse_answer_ecole.ecoles;
        var parse_answer_participant = JSON.parse(response.config.data);
        createdItem.collabImplique = parse_answer_participant.participants2;
        
    })
    .catch(function (error) {
        
        return error
    })
}
