<template >
  <div>
    <v-dialog v-model="dialog" max-width="90%" >
      <v-container grid-list-md text-xs-center  >

        <v-card-title>
          <v-row justify="space-around" align="center">
            <v-col cols="12" sm="2" md="4">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="8" md="4">
              <div v-if="isModif === 0" style="font-family:lato; font-size:25px; text-align:center">Modification</div>
              <div v-if="isModif === 1" style="font-family:lato; font-size:25px; text-align:center">Creation</div>
            </v-col>
            <v-col cols="12" sm="2" md="4">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card color="#FAFAFA">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card-title
                    class="title"
                    style="font-family:lato; background-color: #006a9e; color : white"
                  >
                    <v-icon style="color:white" left>info</v-icon>Informations
                  </v-card-title>
                </v-col>
                <v-card-text>
                  <v-row justify="space-around">
                    <v-col v-if="select_ref === 1" cols="11" sm="11" md="5">
                      <v-text-field v-if="isModif === 0" v-model="editedItem.Intitule" label="Intitule" outlined></v-text-field>
                      <v-text-field v-if="isModif === 1" v-model="createdItem.Intitule" label="Intitule" outlined></v-text-field>
                    </v-col>
                    <v-col v-if=" select_ref === 3" cols="11" sm="11" md="5">
                      <v-text-field v-if="isModif === 0" v-model="editedItem.label_rf_action" label="Intitule" outlined></v-text-field>
                      <v-text-field v-if="isModif === 1" v-model="createdItem.label_rf_action" label="Intitule" outlined></v-text-field>
                    </v-col>
                    <v-col v-if="select_ref === 2 || select_ref === 4 || select_ref === 5 || select_ref === 6" cols="11" sm="11" md="8">
                      <v-text-field v-if="isModif === 0" v-model="editedItem.Intitule" label="Intitule" outlined></v-text-field>
                      <v-text-field v-if="isModif === 1" v-model="createdItem.Intitule" label="Intitule" outlined></v-text-field>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                        <v-autocomplete v-if="isModif === 0 && (select_ref === 1 || select_ref === 3)"
                            v-model="editedItem.type"
                            :items="list_Type"
                            :label="champs_Type"
                            required>
                        </v-autocomplete>
                        <v-autocomplete v-if="isModif === 1 && (select_ref === 1 || select_ref === 3)"
                            v-model="createdItem.type"
                            :items="list_Type"
                            :label="champs_Type"
                            required>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="11" sm="11" md="5"></v-col>
                  </v-row>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn v-if="isModif === 0 && select_ref === 1" color="#006a9e" rounded x-large style="color:white" v-on:click="save(refEcole, select_refEcole)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 0 && select_ref === 2" color="#006a9e" rounded x-large style="color:white" v-on:click="save(refTypeEcole, select_refTypeEcole)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 0 && select_ref === 3" color="#006a9e" rounded x-large style="color:white" v-on:click="save(refAction, select_refAction)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 0 && select_ref === 4" color="#006a9e" rounded x-large style="color:white" v-on:click="save(refTypeAction, select_refTypeAction)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 0" color="#dd0061" rounded x-large style="color:white" v-on:click="close">
              <v-icon left>cancel</v-icon>Annuler
            </v-btn>

            <v-btn v-if="isModif === 1 && select_ref === 1" color="#006a9e" rounded x-large style="color:white" v-on:click="save_createdItem(select_refEcole)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 1 && select_ref === 2" color="#006a9e" rounded x-large style="color:white" v-on:click="save_createdItem(select_refTypeEcole)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 1 && select_ref === 3" color="#006a9e" rounded x-large style="color:white" v-on:click="save_createdItem(select_refAction)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 1 && select_ref === 4" color="#006a9e" rounded x-large style="color:white" v-on:click="save_createdItem(select_refTypeAction)">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn v-if="isModif === 1" color="#dd0061" rounded x-large style="color:white" v-on:click="close_createdItem">
              <v-icon left>cancel</v-icon>Annuler
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-dialog>

    <v-card>
      <v-tabs
        v-model="refItem"
        slider-color="#dd0061"
        fixed-tabs
        background-color="#006a9eff"
        icons-and-text
        dark
        show-arrows
      >
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab>
          Ecoles
          <v-icon class="material-icons">school</v-icon>
        </v-tab>
        <v-tab>
          Types Ecoles
          <v-icon class="material-icons">location_city</v-icon>
        </v-tab>
        <v-tab>
          Actions
          <v-icon class="material-icons">menu_book</v-icon>
        </v-tab>
        <v-tab>
          Types Actions
          <v-icon class="material-icons">work</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="refItem">
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Référentiel des écoles
              <v-spacer></v-spacer>

              <v-text-field
                v-model="searchRefEcole"
                append-icon="search"
                label="Rechercher une école"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-btn
              class="mr-4"
              rounded
              color="#006a9eff"
              style="color:white"
              @click="open_createdItem(select_refEcole)"
              >
              <v-icon left>add_circle</v-icon>Ajouter
              </v-btn>

            </v-card-title>
            

            <v-data-table
              :headers="headers_ecole"
              :items="refEcole"
              :items-per-page="15"
              class="elevation-1"
              :search="searchRefEcole"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item, refEcole, select_refEcole)">edit</v-icon>
                <v-icon small @click="deleteItem(item, refEcole, select_refEcole)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Référentiel des types écoles
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchRefTypeEcole"
                append-icon="search"
                label="Rechercher un type d'école"
                single-line
                hide-details
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-btn
              class="mr-4"
              rounded
              color="#006a9eff"
              style="color:white"
              @click="open_createdItem(select_refTypeEcole)"
              >
              <v-icon left>add_circle</v-icon>Ajouter
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers_type_ecole"
              :items="refTypeEcole"
              :items-per-page="15"
              class="elevation-1"
              :search="searchRefTypeEcole"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item, refTypeEcole, select_refTypeEcole)">edit</v-icon>
                <v-icon small @click="deleteItem(item, refTypeEcole, select_refTypeEcole)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Référentiel des actions
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchRefAction"
                append-icon="search"
                label="Rechercher une action"
                single-line
                hide-details
              ></v-text-field>
              
              <v-spacer></v-spacer>

              <v-btn
              class="mr-4"
              rounded
              color="#006a9eff"
              style="color:white"
              @click="open_createdItem(select_refAction)"
              >
              <v-icon left>add_circle</v-icon>Ajouter
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers_action"
              :items="refAction"
              :items-per-page="15"
              class="elevation-1"
              :search="searchRefAction"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item, refAction, select_refAction)">edit</v-icon>
                <v-icon small @click="deleteItem(item, refAction, select_refAction)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card text>
            <v-card-title>
              Référentiel des types actions
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchRefTypeAction"
                append-icon="search"
                label="Rechercher un type d'action"
                single-line
                hide-details
              ></v-text-field>
              
              <v-spacer></v-spacer>

              <v-btn
              class="mr-4"
              rounded
              color="#006a9eff"
              style="color:white"
              @click="open_createdItem(select_refTypeAction)"
              >
              <v-icon left>add_circle</v-icon>Ajouter
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers_type_action"
              :items="refTypeAction"
              :items-per-page="15"
              class="elevation-1"
              :search="searchRefTypeAction"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item, refTypeAction, select_refTypeAction)">edit</v-icon>
                <v-icon small @click="deleteItem(item, refTypeAction, select_refTypeAction)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>

        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>

//import { getIntitule } from "../API/GET/getIntitule";
import { postRef } from "../API/POST/postRef";
import { putRef } from "../API/PUT/putRef";
import { getRefEcole } from "../API/GET/getRefEcole";
import { getRefTypeEcole } from "../API/GET/getRefTypeEcole";
import { getRefAction } from "../API/GET/getRefAction";
import { getRefTypeAction } from "../API/GET/getRefTypeAction";
import { getIntitule } from "../API/GET/getIntitule";
import { deleteRef } from "../API/DELETE/deleteRef";

export default {
  components: { },
  data() {
    return {
      IdAction: null,
      Intitule: null,
      Type: null,
      searchTypeEcole: "",
      searchTypeAction: "",
      oldParticipants: [],
      participants: [],
      sumTjm: 0,
      members: [],
      ecolesDispo: [],
      listTypeAction: [],
      dialog: false,
      dialog1: false,
      editedIndex: -1,
      editedItem: {},
      createdItem: {},
      modelItem: {},
      searchRefEcole: "",
      searchRefTypeEcole: "",
      searchRefAction: "",
      searchRefTypeAction: "",
      dateDebutProps: {
        locale: "fr",
        color: "#006a9eff",
      },
      timeDebutProps: {
        format: "24hr",
        color: "#006a9eff",
      },
      dateFinProps: {
        locale: "fr",
        color: "#dd0061",
      },
      timeFinProps: {
        format: "24hr",
        color: "#dd0061",
      },    
      actionAValider: [],
      actionAVenir: [],
      actionTerminee: [],
      actionAnnulee: [],
      allAction: [],
      refItem: null,
      headers_ecole: [
        { text: "Intitule de l'école", value: "Intitule" },
        { text: "Type de l'école", value: "type" },
        { text: "Actions", value: "action" },
      ],
      headers_type_ecole: [
        { text: "Type de l'école", value: "Intitule" },
        { text: "Actions", value: "action" },
      ],
      headers_action: [
        { text: "Intitule de l'action", value: "label_rf_action" },
        { text: "Type de l'action", value: "type" },
        { text: "Actions", value: "action" },
      ],
      headers_type_action: [
        { text: "Type de l'action", value: "Intitule" },
        { text: "Actions", value: "action" },
      ],
     
    
      list_TypeEcole: [],
      list_TypeAction: [],
      refAction: [],
      refEcole: [],
      refTypeAction: [],
      refTypeEcole: [],
      refs: [],
      isModif: null,
      select_refEcole: 1,
      select_refTypeEcole: 2,
      select_refAction: 3,
      select_refTypeAction: 4,
      select_ref: null,
      tab_api: ["ecoles", "type_ecole", "ref_actions", "type_action"],
      champs_Type: null,
      list_type: [],

    };
  },

  methods: {

    getRef(){
      
      let tab_api = ["type_ecole", "type_action"];

      getIntitule(tab_api[0])
        .then((tab) => {
          this.list_TypeEcole = tab;
      })
        .catch((error) => {
           return error;      
      });

      getIntitule(tab_api[1])
        .then((tab) => {
          this.list_TypeAction = tab;
      })
      .catch((error) => {
         return error;      
      });

      getRefEcole()
       .then((tab) => {
          this.refEcole = tab[0];
      })
      .catch((error) => {
        return error;      
      }); 

      getRefTypeEcole()
        .then((tab) => {
          this.refTypeEcole = tab[0];
      })
      .catch((error) => {
        return error;      
      }); 

      getRefAction()
        .then((tab) => {
          this.refAction = tab[0];
      })
      .catch((error) => {
        return error;      
      });

      getRefTypeAction()
      .then((tab) => {
          this.refTypeAction = tab[0];
      })
      .catch((error) => {
        return error;      
      }); 
  

    },
    
    editItem(item, ref, choix) {
      this.isModif = 0;
      this.refs = ref;
      this.editedIndex = this.refs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.select_ref = choix;

      switch (choix){
        case 1:
          this.list_Type = this.list_TypeEcole;
          this.champs_Type = "Type de l'école"
          break;
        case 2:
          break;
        case 3:
          this.list_Type = this.list_TypeAction;
          this.champs_Type = "Type de l'action"
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
      }

      this.dialog = true;
    },

    async deleteItem(item, ref, choix) {
      this.refs = ref;
      const index = this.refs.indexOf(item);
      if (confirm("Confirmez vous la suppression de la donnée ?")) {

        await deleteRef(this.refs, index, this.tab_api[choix-1])
          .then(() => {
            this.refs.splice(index, 1);
          })
          .catch((error) => {
            return error;      
          });  

        this.getRef();
        
      }

    
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save(ref, choix) {
      this.refs = ref;
      if (this.editedIndex > -1) {
        Object.assign(this.refs[this.editedIndex], this.editedItem);
        this.editedItem = this.refs[this.editedIndex];

        await putRef(this.editedItem, this.tab_api[choix-1])
          .then(() => {
          
    
          })
          .catch((error) => {
           return error;        
          });   
      } else {
        alert("Impossible de mettre à jour l'action");
      }
      this.close();
    },

    open_createdItem(choix) {
      this.isModif = 1;
      this.select_ref = choix;

      switch (choix){
        case 1:
          this.list_Type = this.list_TypeEcole;
          this.champs_Type = "Type de l'école"
          break;
        case 2:
          break;
        case 3:
          this.list_Type = this.list_TypeAction;
          this.champs_Type = "Type de l'action"
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
      }

      this.dialog = true;

    },

    close_createdItem() {
      this.dialog = false;
    },

    async save_createdItem(choix) {

      this.createdItem.IdRef = null;
      await postRef(this.createdItem, this.tab_api[choix-1])
        .then(() => {
        })
        .catch((error) => {
          return error;      
        }); 

      this.getRef();
      this.createdItem.Intitule = null,
      this.createdItem.type = null,
      this.close_createdItem();   
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getRef();
  },

  computed: {
  },

};
</script>