import axios from 'axios';
import qs from 'querystring'

//Lien vers le domaine Amazon Cognito
let baseUrl = process.env.VUE_APP_COGNITO_BASEURL

//Id Client et clé secrète du client d'application

const requestBody = {
    client_id: process.env.VUE_APP_COGNITO_CLIENTID,
    client_secret: process.env.VUE_APP_COGNITO_CLIENTSECRET,
} 

//Récupération du token
export function getToken() {
    let options = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    //Requete http POST avec les identifiants ainsi que les paramètres du header de la requête
    return axios.post(baseUrl, qs.stringify(requestBody), options)
        .then(function (response) {
            // handle success
            return response.data;
        })
        .catch(function (error) {
            // handle error
            return error
        })
}





