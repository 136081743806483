import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function deleteRef(refs, index, api) {

  let options = {
    headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token }
  };

  return axios.delete(`${baseUrl}/` + api + `/${refs[index].IdRef}`, options)
    .then(() => {
    })
    .catch(function (error) {
      return error
    })
}
