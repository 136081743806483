import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export async function putActionDashboard(editedItem) {

    let options = {
      headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    return axios.put(`${baseUrl}/actions`+ `/${editedItem.id_action}`,{
      IdAction : editedItem.id_action,
      titre: editedItem.titre,
      status:
        editedItem.status === "A venir/En cours"
          ? "Validée"
          : editedItem.status === "Terminée"
          ? "Validée"
          : editedItem.status,
      informations:  editedItem.informations
      ? editedItem.informations
      : null,
      dateDebut: editedItem.dateDebut
      ? editedItem.dateDebut
      : null,
      dateFin:editedItem.dateFin
      ? editedItem.dateFin
      : null,
      budget: editedItem.budget
      ? editedItem.budget
      : null,
      a_renouveler: editedItem.to_renew
      ? editedItem.to_renew
      : null,
      participants: editedItem.nb_participants
      ? editedItem.nb_participants
      : null,
      cout_additionnel: editedItem.cout_additionnel
        ? editedItem.cout_additionnel
        : 0,
      feedback: editedItem.feedback
        ? editedItem.feedback
        : null,
      commentaire: editedItem.commentaire
        ? editedItem.commentaire
        : null,
      cv_recuperes_commerce: editedItem.nb_cv_recup_com
        ? editedItem.nb_cv_recup_com
        : null,
      cv_p1_commerce: editedItem.nb_cv_p1_com
        ? editedItem.nb_cv_p1_com
        : null,
      cv_recrutes_commerce: editedItem.nb_cv_recru_com
        ? editedItem.nb_cv_recru_com
        : null,
      cv_recuperes_ingenieurs: editedItem.nb_cv_recup_ing
        ? parseInt(editedItem.nb_cv_recup_ing)
        : null,
      cv_p1_ingenieurs: editedItem.nb_cv_p1_ing
        ? editedItem.nb_cv_p1_ing
        : null,
      cv_recrutes_ingenieurs: parseInt(editedItem.nb_cv_recru_ing)
        ? editedItem.nb_cv_recru_ing
        : null,
      cv_recuperes_universite: editedItem.nb_cv_recup_uni
        ? editedItem.nb_cv_recup_uni
        : null,
      cv_p1_universite: editedItem.nb_cv_p1_uni
        ? editedItem.nb_cv_p1_uni
        : null,
      cv_recrutes_universite: editedItem.nb_cv_recru_uni
        ? editedItem.nb_cv_recru_uni
        : null,
      typeAction: editedItem.type_action
        ? editedItem.type_action
        : null,
      ecoles: editedItem.ecoles
      ? editedItem.ecoles
      : null,
      contactsEcole: editedItem.contact_ecole
      ? editedItem.contact_ecole
      : null,
      participants2: editedItem.participants2,
      dbr: null,
      }, options)    
      
      .then(() => {
      })
      .catch(function (error) {
        return error
      })
}
