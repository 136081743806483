import firebase from 'firebase'

var firebaseConfig = {
  //MC2I-COCKPIT-DEV
	/*apiKey: "AIzaSyCcuU4afDqGxeAEmZZE0AN1wpdqlkC3068",
	authDomain: "cockpit-mc2i-dev-66ff8.firebaseapp.com",
	databaseURL: "https://cockpit-mc2i-dev-66ff8.firebaseio.com",
	projectId: "cockpit-mc2i-dev-66ff8",
	storageBucket: "cockpit-mc2i-dev-66ff8.appspot.com",
	messagingSenderId: "419329430049",
	appId: "1:419329430049:web:a6b76413aa90d6ed148960"*/

  //MC2I-COCKPIT-DEV-2
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL:process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
  /*//MC2I PROD
  apiKey: "AIzaSyD9RwVUynIC8i20plRbPnAVvvkw0-aNoQg",
  authDomain: "mc2i-cockpit.firebaseapp.com",
  databaseURL: "https://mc2i-cockpit.firebaseio.com",
  projectId: "mc2i-cockpit",
  storageBucket: "mc2i-cockpit.appspot.com",
  messagingSenderId: "370779337144",
  appId: "1:370779337144:web:66e3f39654d99ac84e1f4d",
  measurementId: "G-2BWJ32E6T2"*/
};




// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);


//const firestore = firebaseApp.firestore();*/

export default firebaseApp