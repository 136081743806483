import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from '@/router'
import store from '@/store'
import firebase from './firebase'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueResource from 'vue-resource'



Vue.use(VueResource);

Vue.config.productionTip = false;



let app = '';

// check if the user is logged in on every reload

firebase.auth().onAuthStateChanged( () => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
