<template>
  <v-app>
    <NavBar></NavBar>
    <v-main style="background-color : white">
    <router-view ></router-view>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import NavBar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>
