import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export async function putTjm(itemToView) {

    let options = {
      headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    
  
    return axios.put(`${baseUrl}/tjm`+ `/${itemToView.id_action}`,{
      IdAction : itemToView.id_action,
      sumTjm: itemToView.sumTjm,
      }, options)    
      .then(() => {

      })
      .catch(function (error) {
        return error
      })
}
