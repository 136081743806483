<template>
 <v-container lass="fill-height" fluid style="background-color : rgba(0,106,158,0.1)">
  <div >
    <v-dialog v-model="dialog" max-width="90%">
      <v-container grid-list-md text-xs-center >
        <v-card-title>
          <v-row justify="center">
            <div style="font-family:lato; font-size:25px; text-align:center">Modifier action ecole</div>
          </v-row>
        </v-card-title>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card outlined color="white">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card-title
                    class="title"
                    style="font-family:lato; background-color: #006a9e; color : white"
                  >
                    <v-icon style="color:white" left>question_answer</v-icon>Debrief
                  </v-card-title>
                </v-col>
                <v-col cols="11">
                  <v-card color="#FAFAFA">
                    <v-card-subtitle
                      style="font-family:lato; background-color: #164667; color : white"
                    >A remplir par le consultant</v-card-subtitle>

                    <v-card-text style="margin-top : 1%">
                      <v-row justify="space-around">
                        <v-col cols="12" sm="11" md="4">
                          <v-text-field
                            v-model="editedItem.nb_participants"
                            label="Nombre de participants"
                            type="number"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-text-field
                            v-model="editedItem.cout_additionnel"
                            label="Cout additionnel"
                            type="number"
                            prefix="€"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-textarea v-model="editedItem.feedback" label="Feedback" outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-textarea v-model="editedItem.commentaire" label="Commentaire" outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <p>Recommandez-vous le renouvellement de cette action ?</p>
                          <v-radio-group v-model="editedItem.to_renew">
                            <v-radio label="Oui" value="oui"></v-radio>
                            <v-radio label="Non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn color="#006a9e" rounded x-large style="color:white" v-on:click="save">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn color="#dd0061" rounded x-large style="color:white" v-on:click="close">
              <v-icon left>cancel</v-icon>Annuler
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-dialog>

    <v-card style="margin-left:2%;margin-right:2%;margin-top:8%;margin-bottom:16%  " >
      <v-tabs
        v-model="debriefsItem"
        slider-color="#dd0061"
        fixed-tabs
        background-color="#006a9eff"
        icons-and-text
        dark
        show-arrows
        
      >
        <v-tabs-slider color="teal lighten-3"  ></v-tabs-slider>
        <v-tab >
          Mes debriefs
          <v-icon class="material-icons">question_answer</v-icon>
        </v-tab>

        <v-tabs-items v-model="debriefsItem"  >
          <v-tab-item  >
            <v-card text style="background-color:rgba(0,106,158,0.2);padding: 30px;">
              <v-card-title>
                Mes debriefs
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchMesDebriefs"
                  append-icon="search"
                  label="Rechercher une action école"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="mesDebriefs"
                :items-per-page="15"
                class="elevation-1"
                :search="searchMesDebriefs"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
  </v-container>
</template>
<script>

import { getAction } from "../API/GET/getAction";
import { putActionDebrief } from "../API/PUT/putActionDebrief";
import moment from "moment";


export default {
  components: {},
  data() {
    return {
      searchMesDebriefs: "",
      mesDebriefs: [],
      headers: [
        {
          text: "Titre de l'action",
          value: "titre",
        },
        { text: "Type de l'action", value: "type_action" },
        { text: "Ecoles", value: "ecoles" },
        { text: "Date de début", value: "dateDebut" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action" },
        
      ],
      debriefsItem: null,
      dialog: false,
      editedItem: {},
      editedIndex: -1,
    };
  },
  methods: {
    getColor(status) {
      if (status === "A valider") return "orange";
      else if (status === "A venir/En cours") return "green";
      else if (status === "Terminée") return "#dd0061";
      else return "red";
    },
    isFinish(action) {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
     
     let todayDateString =
        mm + "/" + dd + "/" + yyyy ;
      
      let actionDateString =
        action.dateFin.substr(3, 2) +
        "/" +
        action.dateFin.substr(0, 2) +
        "/" +
        action.dateFin.substr(6, 4) 
       ;

      let todayDate = Date.parse(todayDateString);
      let actionDate = Date.parse(actionDateString);

      if (actionDate > todayDate) {
        return false;
      } else {
        return true;
      }
    },
       
    
    editItem(item) {
      this.editedIndex = this.mesDebriefs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.mesDebriefs[this.editedIndex], this.editedItem);
        this.editedItem = this.mesDebriefs[this.editedIndex];

        putActionDebrief(this.editedItem)
          .then(() => {
          })
          .catch((error) => {
            return error;      
          });  

      } else {
        alert("Impossible de mettre à jour l'action");
      }

      this.close();
    },
    close() {
      this.dialog = false;
    },
  },

  created() {

    let actionTerminee1;
    let actionTerminee2 = [];

    getAction()
      .then((tab) => {

        this.allAction = tab[0];
        
      tab[0]=tab[0].map((row)=>{
        if((row.dateDebut!="0000/00/00")&&(row.dateDebut!=null))
        row.dateDebut=moment(row.dateDebut,"YYYY/MM/DD").format("DD/MM/YYYY");
        else 
        row.dateDebut="00/00/0000";
        if((row.dateFin!="0000/00/00")&&(row.dateFin!=null))
        row.dateFin=moment(row.dateFin,"YYYY/MM/DD").format("DD/MM/YYYY");
        else 
        row.dateFin="00/00/0000";
        return row ;
       
      })
        
        for(let i = 0; i < tab[0].length; i++){
        
          switch (tab[0][i].status){
            case "Validée":
              if (this.isFinish(tab[0][i]) && tab[0][i].email == this.$store.getters.email){
                actionTerminee1 = tab[0][i];
                tab[0][i].status = "Terminée";
                actionTerminee2.push(actionTerminee1);
                
              }
              break;
          }
        }
        this.mesDebriefs = actionTerminee2;
        
        
      })
      .catch((error) => {
        return error;      
      });

  },
};
</script>