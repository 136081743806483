import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export async function putRef(editedItem, tab_api) {

    let options = {
      headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
   
    return axios.put(`${baseUrl}/`+tab_api+ `/${editedItem.IdRef}`,{

      IdRef : editedItem.IdRef,
      Intitule: tab_api =="ref_actions"? editedItem.label_rf_action :editedItem.Intitule,
      type : editedItem.type,
      
      

      }, options)    
      
      .then(() => {
      })
      .catch(function (error) {
        return error
      })
}
