import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function deleteAction(allAction, index) {

  let options = {
    headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token }
  };

  return axios.delete(`${baseUrl}/actions/${allAction[index].id_action}`, options)
    .then(() => {
    })
    .catch(function (error) {
      return error
    })
}
