
<template>
  <div>
    <ViewItem></ViewItem>
    <v-dialog v-model="dialog" max-width="90%">
      <v-container grid-list-md text-xs-center style="background-color : white">

        <v-card-title>
          <v-row justify="space-around" align="center">
            <v-col cols="12" sm="2" md="4">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="8" md="4">
              <div style="font-family:lato; font-size:25px; text-align:center">Modifier action ecole</div>
            </v-col>
            <v-col cols="12" sm="2" md="4">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card color="#FAFAFA">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card-title
                    class="title"
                    style="font-family:lato; background-color: #006a9e; color : white"
                  >
                    <v-icon style="color:white" left>info</v-icon>Informations générales
                  </v-card-title>
                </v-col>
                <v-card-text>
                  <v-row justify="space-around">
                    <v-col cols="11" sm="11" md="5">
                      <v-text-field v-model="editedItem.titre" label="Titre de l'action" outlined></v-text-field>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-text-field
                        v-model="editedItem.budget"
                        label="Budget"
                        type="number"
                        prefix="€"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="11" sm="11" md="5"> 
                      
                      <v-text-field v-model="editedItem.dateDebut" label="Date de début" outlined></v-text-field>
                     
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-text-field v-model="editedItem.dateFin"  label="Date de fin" outlined></v-text-field>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-autocomplete
                        v-model="ecoleArray"
                        :items="ecolesDispo"
                        chips
                        small-chips
                        label="Ecole(s)(*)"
                        multiple
                        :search-input.sync="searchEcoles"
                        @change="searchEcoles = ''"
                      >
                        <template v-slot:selection="{ item, parent }">
                          <v-chip :color="'#dd0061'" :text-color="'white'" label small>
                            <span class="pr-2" style="font-family:lato">{{ item }}</span>
                            <v-icon small @click="parent.selectItem(item)">close</v-icon>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-select
                        v-model="editedItem.status"
                        :items="statusDispo"
                        label="Status"
                        dense
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-autocomplete
                        v-model="editedItem.type_action"
                        :items="listTypeAction"
                        label="Type d'action"
                        item-text="type_action"
                        item-value="type_action"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-textarea v-model="editedItem.informations" label="Informations" outlined></v-textarea>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-textarea
                        v-model="editedItem.contact_ecole"
                        label="Contacts ecole"
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="11" sm="11" md="5">
                      <v-autocomplete
                        v-model="participantsArray"
                        :items="members"
                        chips
                        small-chips
                        label="Participants mc2i(*)"
                        multiple
                        :search-input.sync="searchParticipants"
                        @change="searchParticipants = ''"
                      >
                        <template v-slot:selection="{item, parent}">
                          <v-chip :color="'#dd0061'" :text-color="'white'" label small>
                            <span class="pr-2" style="font-family:lato">{{item}}</span>
                            <v-icon small @click="parent.selectItem(item)">close</v-icon>
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="11" sm="11" md="5"></v-col>
                  </v-row>
                </v-card-text>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card outlined color="white">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card-title
                    class="title"
                    style="font-family:lato; background-color: #006a9e; color : white"
                  >
                    <v-icon style="color:white" left>question_answer</v-icon>Debrief
                  </v-card-title>
                </v-col>
                <v-col cols="11">
                  <v-card color="#FAFAFA">
                    <v-card-subtitle
                      style="font-family:lato; background-color: #164667; color : white"
                    >A remplir par le consultant</v-card-subtitle>

                    <v-card-text style="margin-top : 1%">
                      <v-row justify="space-around">
                        <v-col cols="12" sm="11" md="4">
                          <v-text-field
                            v-model="editedItem.nb_participants"
                            label="Nombre de participants"
                            type="number"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-text-field
                            v-model="editedItem.cout_additionnel"
                            label="Cout additionnel"
                            type="number"
                            prefix="€"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-textarea v-model="editedItem.feedback" label="Feedback" outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <v-textarea v-model="editedItem.commentaire" label="Commentaire" outlined></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="11" md="4">
                          <p>Recommandez-vous le renouvellement de cette action ?</p>
                          <v-radio-group v-model="editedItem.to_renew">
                            <v-radio label="Oui" value="oui"></v-radio>
                            <v-radio label="Non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="11">
                  <v-card color="#FAFAFA">
                    <v-card-subtitle
                      style="font-family:lato; background-color: #164667; color : white"
                    >A remplir par les RH</v-card-subtitle>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="12" md="4">
                        <v-card outlined color="#FAFAFA">
                          <v-card-subtitle class="title">Ecole de commerce</v-card-subtitle>
                          <v-row align="center" justify="center">
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_recup_com"
                                label="Nombre de CV récupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_p1_com"
                                label="Nombre de CV P1 recupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_recru_com"
                                label="Nombre de CV recrutés"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-card outlined color="#FAFAFA">
                          <v-card-subtitle class="title">Ecole d'ingénieurs</v-card-subtitle>
                          <v-row align="center" justify="center">
                            <v-col cols="10">
                               <v-text-field
                                v-model="editedItem.nb_cv_recup_ing"
                                label="Nombre de CV récupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_p1_ing"
                                label="Nombre de CV P1 recupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_recru_ing"
                                label="Nombre de CV recrutés"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-card outlined color="#FAFAFA">
                          <v-card-subtitle class="title">Université</v-card-subtitle>
                          <v-row align="center" justify="center">
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_recup_uni"
                                label="Nombre de CV récupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_p1_uni"
                                label="Nombre de CV P1 recupérés suite à l'intervention"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                v-model="editedItem.nb_cv_recru_uni"
                                label="Nombre de CV recrutés"
                                type="number"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-btn color="#006a9e" rounded x-large style="color:white" v-on:click="save">
              <v-icon left>save</v-icon>Enregistrer
            </v-btn>
            <v-btn color="#dd0061" rounded x-large style="color:white" v-on:click="close">
              <v-icon left>cancel</v-icon>Annuler
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-dialog>

    <v-card>
      <v-tabs
        v-model="actionsItem"
        slider-color="#dd0061"
        fixed-tabs
        background-color="#006a9eff"
        icons-and-text
        dark
        show-arrows
      >
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab>
          Actions à valider
          <v-icon class="material-icons">check_box_outline_blank</v-icon>
        </v-tab>
        <v-tab>
          Actions à venir/en cours
          <v-icon class="material-icons">check_box</v-icon>
        </v-tab>
        <v-tab>
          Actions terminées
          <v-icon class="material-icons">timer_off</v-icon>
        </v-tab>
        <v-tab>
          Actions annulées
          <v-icon class="material-icons">cancel</v-icon>
        </v-tab>
        <v-tab>
          Toutes les actions
          <v-icon class="material-icons">all_inclusive</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="actionsItem">
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Actions à valider
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActionAValider"
                append-icon="search"
                label="Rechercher une action école"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="actionAValider"
              :items-per-page="15"
              class="elevation-1"
              :search="searchActionAValider"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="material-icons mr-2" @click="viewItem(item)">remove_red_eye</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Actions à venir/en cours
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActionAVenir"
                append-icon="search"
                label="Rechercher une action école"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="actionAVenir"
              :items-per-page="15"
              class="elevation-1"
              :search="searchActionAVenir"
            >
              <template  v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="material-icons mr-2" @click="viewItem(item)">remove_red_eye</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Actions terminées
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActionTerminees"
                append-icon="search"
                label="Rechercher une action école"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="actionTerminee"
              :items-per-page="15"
              class="elevation-1"
              :search="searchActionTerminees"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="material-icons mr-2" @click="viewItem(item)">remove_red_eye</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card text>
            <v-card-title>
              Actions annulées
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActionAnnulee"
                append-icon="search"
                label="Rechercher une action école"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="actionAnnulee"
              :items-per-page="15"
              class="elevation-1"
              :search="searchActionAnnulee"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="material-icons mr-2" @click="viewItem(item)">remove_red_eye</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card text>
            <v-card-title>
              Toutes les actions
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchActionAll"
                append-icon="search"
                label="Rechercher une action école"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="allAction"
              :items-per-page="15"
              class="elevation-1"
              :search="searchActionAll"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="material-icons mr-2" @click="viewItem(item)">remove_red_eye</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>

import ViewItem from "./ViewItem.vue";
import { getIntitule } from "../API/GET/getIntitule";
import { getEmail } from "../API/GET/getEmail";
import { getAction } from "../API/GET/getAction";
import { putActionDashboard } from "../API/PUT/putActionDashboard";
import { deleteAction } from "../API/DELETE/deleteAction";
import { getTjm } from "../API/GET/getTjm";
import moment from "moment";


export default {
  components: { ViewItem, },
  data() {
    return {
      IdAction: null,
      searchParticipants: "",
      searchEcoles: "",
      oldParticipants: [],
      nb_participants: [],
      sumTjm: 0,
      members: [],
      ecolesDispo: [],
      listTypeAction: [],
      statusDispo: ["A valider", "Validée", "Annulée"],
      dialog: false,
      editedIndex: -1,
      editedItem: {},
      searchActionAValider: "",
      searchActionAVenir: "",
      searchActionTerminees: "",
      searchActionAnnulee: "",
      searchActionAll: "",
      dateDebutProps: {
        locale: "fr",
        color: "#006a9eff",
      },
      timeDebutProps: {
        format: "24hr",
        color: "#006a9eff",
      },
      dateFinProps: {
        locale: "fr",
        color: "#dd0061",
      },
      regleDateDebut: [(v) => !!v || "Veuillez renseigner la date"],
      regleDateFin: [(v) => !!v || "Veuillez renseigner la date"],
      timeFinProps: {
        format: "24hr",
        color: "#dd0061",
      },    
      actionAValider: [],
      actionAVenir: [],
      actionTerminee: [],
      actionAnnulee: [],
      allAction: [],
      actionsItem: null,
      headers: [
        {
          text: "Titre de l'action",
          value: "titre",
        },
        { text: "Type de l'action", value: "type_action" },
        { text: "Ecoles", value: "ecoles" },
        { text: "Date de début", value: "dateDebut" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action" },
      ],
      participants2_tjm: null,
      dateVerifDebut:"",
      dateVerifFin: "",
      dateCheckDebut: "",
      dateCheckFin: "",
    };
    
  },

  methods: {
    
    getColor(status) {
      if (status === "A valider") return "orange";
      else if (status === "A venir/En cours") return "green";
      else if (status === "Terminée") return "#dd0061";
      else return "red";
    },

    editItem(item) {
      this.editedIndex = this.allAction.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.oldParticipants = this.editedItem.participants2;
      this.editedItem.status =
        this.editedItem.status === "A venir/En cours"
          ? "Validée"
          : this.editedItem.status === "Terminée"
          ? "Validée"
          : this.editedItem.status;
      
    //this.editedItem.dateDebut=moment(this.editedItem.dateDebut,"DD/MM/YYYY").format("YYYY/MM/DD");
    //this.editedItem.dateFin=moment(this.editedItem.dateFin,"DD/MM/YYYY").format("YYYY/MM/DD");
      this.dateVerifDebut =this.editedItem.dateDebut;

      this.dateVerifFin = this.editedItem.dateFin;
      
  
      this.dialog = true;
      
       
       
      
       
      
    },

    deleteItem(item) {
      const index = this.allAction.indexOf(item);
      if (confirm("Confirmez vous la suppression de l'action école ?")) {

        deleteAction(this.allAction, index)
          .then(() => {
            this.allAction.splice(index, 1);
            this.updateAction();
          })
          .catch((error) => {
            return error;      
          });  

      }
    },
     moment() {
      return moment();
    },

    viewItem(item) {
      this.$store.commit("setDialogViewItem", true);
      this.$store.commit("setItemToView", item);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    get_Action_Status(){
      let actionAValider1, actionTerminee1, actionAnnulee1, actionAVenir1;
      let actionAValider2 = [];
      let actionAnnulee2 =  [];
      let actionTerminee2 = [];
      let actionAVenir2 = [];
      getAction()
        .then((tab) => {

        this.allAction = tab[0];
       
 
      tab[0]=tab[0].map((row)=>{
        if((row.dateDebut!="0000/00/00")&&(row.dateDebut!=null))
        row.dateDebut=moment(row.dateDebut,"YYYY/MM/DD").format("DD/MM/YYYY");
        else 
        row.dateDebut="00/00/0000";
        if((row.dateFin!="0000/00/00")&&(row.dateFin!=null))
        row.dateFin=moment(row.dateFin,"YYYY/MM/DD").format("DD/MM/YYYY");
        else 
        row.dateFin="00/00/0000";
        return row ;
       
      })
        for(let i = 0; i < tab[0].length; i++){

          switch (tab[0][i].status){
            case "A valider":
              actionAValider1 = tab[0][i];
              actionAValider2.push(actionAValider1);
              
              break;
            case "Validée":
              if (this.isFinish(tab[0][i])==true){
                actionTerminee1 = tab[0][i];
                tab[0][i].status = "Terminée";
                actionTerminee2.push(actionTerminee1);
              } else {
                actionAVenir1 = tab[0][i];
                tab[0][i].status = "A venir/En cours";
                actionAVenir2.push(actionAVenir1);
               
              }
              break;
            case "Annulée":
              actionAnnulee1 = tab[0][i];
              actionAnnulee2.push(actionAnnulee1);
              break;
          }
          
        }

        for(let i = 0; i < tab[0].length; i++){
          if(this.allAction[i].presenceAction == 0){
            this.allAction[i].presenceAction = "Non";
          }else{
            if(this.allAction[i].presenceAction == 1){
              this.allAction[i].presenceAction = "Oui";
            }
          }
          
        }


      })
      .catch((error) => {
        return error;      
      });
        
        this.actionAValider = actionAValider2;
        this.actionAVenir = actionAVenir2;
        this.actionTerminee = actionTerminee2;
        this.actionAnnulee = actionAnnulee2;

    },
    

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.allAction[this.editedIndex], this.editedItem);
        this.updateAction();
        this.editedItem = this.allAction[this.editedIndex];

        this.editedItem.ecoles = this.ecoleArray;
        this.editedItem.participants2 = this.participantsArray;
        this.editedItem.dateDebut=moment(this.editedItem.dateDebut,"DD/MM/YYYY").format("YYYY/MM/DD");
        this.editedItem.dateFin=moment(this.editedItem.dateFin,"DD/MM/YYYY").format("YYYY/MM/DD");
        await putActionDashboard(this.editedItem)
          .then(() => {
          })
          .catch((error) => {
            return error;      
          });   
        this.editedItem.dateDebut=moment(this.editedItem.dateDebut,"YYYY/MM/DD").format("DD/MM/YYYY");
        this.editedItem.dateFin=moment(this.editedItem.dateFin,"YYYY/MM/DD").format("DD/MM/YYYY");

      } else {
        alert("Impossible de mettre à jour l'action");
      }

      await getTjm(this.editedItem.IdAction)
        .then((tab) => {
          this.editedItem.sumTjm = tab[0];
          this.editedItem.participants2_tjm = tab[1]
      })
      .catch((error) => {
        return error;      
      });

      this.close();
    },

    getArrayTjm(oldArray, newArray) {
      for (var i = 0; i < oldArray.length; i++) {
        for (var j = 0; j < newArray.length; j++) {
          if (oldArray[i].email == newArray[j].email) {
            newArray[j].tjm = oldArray[i].tjm;
          }
        }
      }
      return newArray;
    },

    isFinish(action) {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
     
      //Conversion au format mm/dd/yyyy hh:mm
      let todayDateString =
        mm + "/" + dd + "/" + yyyy ;
      
      let actionDateString =
        action.dateFin.substr(3, 2) +
        "/" +
        action.dateFin.substr(0, 2) +
        "/" +
        action.dateFin.substr(6, 4) 
       ;

      let todayDate = Date.parse(todayDateString);
      let actionDate = Date.parse(actionDateString);

      if (actionDate > todayDate) {
        return false;
      } else {
        return true;
      }
    },
  
      updateAction() {
      let actionAValider2 = [];
      let actionTerminee2 = [];
      let actionAVenir2 = [];
      let actionAnnulee2 =  [];
      this.allAction.forEach((type_action) => {
        switch (type_action.status) {
          case "A valider":
            actionAValider2.push(type_action);
            break;
          case "Validée":
          case "Terminée":
          case "A venir/En cours":
            if (this.isFinish(type_action)) {
              type_action.status = "Terminée";
              actionTerminee2.push(type_action);
              
            } else{
              type_action.status = "A venir/En cours";
              actionAVenir2.push(type_action);
            }
            break;
          case "Annulée":
            actionAnnulee2.push(type_action);
            break;
        }
      });
      this.actionAValider = actionAValider2;
      this.actionAVenir = actionAVenir2;
      this.actionAnnulee = actionAnnulee2;
      this.actionTerminee = actionTerminee2;
    },

    compare(a, b) {
      if (a.groupe < b.groupe) {
        return -1;
      }
      if (a.groupe > b.groupe) {
        return 1;
      }
      return 0;
    },
  },
   

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {

    let tab_api = ["ref_actions", "ecoles"];

    getIntitule(tab_api[0])
      .then((tab) => {
        this.listTypeAction = tab;
      })
      .catch((error) => {
         return error;      
    });

    getIntitule(tab_api[1])
      .then((tab) => {
        this.ecolesDispo = tab;
      })
      .catch((error) => {
        return error;      
      });    


    getEmail()
      .then((tab) => {
        this.members = tab;
      })
      .catch((error) => {
        return error;      
      });   

    
    this.get_Action_Status();

  },
  

  beforeMount() {
    this.members = this.$store.getters.gsuiteMembers;
  },

  computed: {

    ecoleArray: {
      get: function () {
        if (
          this.editedItem.ecoles !== undefined &&
          typeof this.editedItem.ecoles === "string"
        ) {
          var array = this.editedItem.ecoles.split(",");
          return array;
        }
        return this.editedItem.ecoles;
      },
      set: function (ecole) {
        this.editedItem.ecoles = ecole;
      },
    },

    participantsArray: {
      get: function () {
        if (this.editedItem.participants2 != null) {
          if (
            this.editedItem.participants2 !== undefined &&
            typeof this.editedItem.participants2 === "string"
          ) {
            var array = this.editedItem.participants2.split(",");
            return array;
          } else if (this.editedItem.participants2 !== undefined) {
            var arr = [];
            for (var i = 0; i < this.editedItem.participants2.length; i++) {
              this.editedItem.participants2[i].email
                ? arr.push(this.editedItem.participants2[i].email)
                : null;
            }
            return arr.length > 0 ? arr : this.editedItem.participants2;
          }

          return this.editedItem.participants2;
        }
        var emptyArray = [];
        return emptyArray;
      },
      set: function (participants2) {
        this.editedItem.participants2 = participants2;
      },
      
    },

  },
};
</script>
