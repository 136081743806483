import axios from "axios";
import store from "../store";


let baseUrl = process.env.VUE_APP_APIGW_BASEURL

export function getRole(email) {
    let options = {
      headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    return axios.get(`${baseUrl}/roles/` + email, options)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error
      })
  

}