import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export function getTjm(idaction) {
    let tab = [];
    let options = {
        headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };
    
    return axios.get(`${baseUrl}/tjm`, options)
        .then((response) => {
            let tjm = 0;
            let detail_tjm = '';

            for(let i = 0; i < response.data.length; i++){
                if(response.data[i].id_action == idaction){
                    tjm = response.data[i].sum_tjm;
                    detail_tjm = response.data[i].participants2_tjm;
                    tab[0] = tjm;
                    tab[1] = detail_tjm;
                }
            }
            
            return tab;
        })
        .catch(function (error) {
            return error
        })
}