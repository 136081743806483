import axios from "axios";
import store from "../../store";

let baseUrl = process.env.VUE_APP_APIGW_BASEURL;

export async function putActionDebrief(editedItem) {

    let options = {
      headers: { 'Authorization': store.getters.all_token.token_type + ' ' + store.getters.all_token.access_token}
    };

    return axios.put(`${baseUrl}/actions/${editedItem.IdRef}/debrief`, {
      IdAction : editedItem.id_action,
      a_renouveler: editedItem.to_renew?editedItem.to_renew:null ,
      participants: editedItem.nb_participants,
      cout_additionnel: editedItem.cout_additionnel
        ? editedItem.cout_additionnel
        : null,
      feedback: editedItem.feedback
        ? editedItem.feedback
        : null,
      commentaire: editedItem.commentaire
        ? editedItem.commentaire
        : null,
      dbr : 1,
    }, options)    
    .then(() => {
    })
    .catch(function (error) {
      return error
    })
}
