<template >
  <div >
    <v-navigation-drawer  v-model="drawer" app>
      <v-list nav>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title style="font-family:lato">My Campus</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="!userLogedIn">
            <v-list-item-title style="font-family:lato">
              <v-btn text @click="logoutFromFirebase">Login</v-btn>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-for="item in itemsAdmin" :key="item.title" v-else-if="userAuthorized == 2">
            <v-btn style="font-family:lato" text :to="item.link">{{item.title}}</v-btn>
          </v-list-item>
          <v-list-item v-for="item1 in itemsAgent" :key="item1.title" v-else-if="userAuthorized == 1">
            <v-btn style="font-family:lato" text :to="item1.link">{{item1.title}}</v-btn>
          </v-list-item>
          <v-list-item v-for="item2 in itemsUser" :key="item2.title" v-else-if="userAuthorized == 0">
            <v-btn style="font-family:lato" text :to="item2.link">{{item2.title}}</v-btn>
          </v-list-item>
          <v-list-item v-if="userLogedIn">
            <v-btn text @click="logoutFromFirebase">Logout</v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- display the navigation bar -->
    <v-app-bar app
      color="#006a9e">
      <v-toolbar-items class="hidden-xs-only">
        <v-img
          alt="MyCampus logo"
          class="shrink mr-2"
          contain
          elevation="100"
          src="../assets/logo_campus.png"
          transition="scale-transition"
          width="50"
        />
      </v-toolbar-items>

      <v-toolbar-items class="d-sm-none">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <!-- navigation bar links -->
      <!--<v-toolbar-items class="hidden-xs-only" v-if="!userLogedIn">
        <v-btn text @click="logoutFromFirebase">Login</v-btn>
        <v-spacer></v-spacer>
      </v-toolbar-items>-->
      <!-- sign out button -->
      <v-toolbar-items class="hidden-xs-only" v-if="userLogedIn"  >
        <template v-if="userAuthorized == 2">
          <v-btn style="color:white;" text v-for="item in itemsAdmin" :key="item.title" :to="item.link">{{item.title}}</v-btn>
        </template>
        <template v-if="userAuthorized == 1">
          <v-btn style="color:white;" text v-for="item in itemsAgent" :key="item.title" :to="item.link">{{item.title}}</v-btn>
        </template>
        
        <template v-else-if="userAuthorized == 0">
          <v-btn  text v-for="item in itemsUser" :key="item.title" :to="item.link">{{item.title}}</v-btn>
        </template>

        <v-btn style="background-color : #006a9e">
          <div class="text-center">
            <v-menu offset-y >
             
              <template v-slot:activator="{ on }" >
               <v-row justify="space-around" >
                  <v-avatar >
                     <img v-on="on" :src="getAvatarImg"  alt  />
                  </v-avatar>
                </v-row>
              </template>
              
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn text @click="logoutFromFirebase">Logout</v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              
            </v-menu>
          </div>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="d-sm-none">
        <v-img
          alt="MyCampus Logo"
          class="shrink mr-2"
          contain
          src="../assets/logo_campus.png"
          transition="scale-transition"
          width="50"
          right
        />
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      drawer: false,
    };
  },
  mounted() {
    let _this = this;
    window.addEventListener("resize", function () {
      if (window.innerWidth > 600) {
        _this.drawer = false;
      }
    });
  },
  computed: {
        itemsAdmin() {
      let menuItems = [
        {
          title: "Formulaire",
          icon: "no_encryption",
          link: "/",
        },
        {
          title: "Dashboard",
          icon: "no_encryption",
          link: "/dashboard",
        },
        {
          title: "Admin",
          icon: "no_encryption",
          link: "/admin",
        },
        {
          title: "Mes debriefs",
          icon: "no_encryption",
          link: "/debrief",
        },
      ];
      return menuItems;
    },
    itemsAgent() {
      let menuItems = [
        {
          title: "Formulaire",
          icon: "no_encryption",
          link: "/",
        },
        {
          title: "Dashboard",
          icon: "no_encryption",
          link: "/dashboard",
        },
        {
          title: "Mes debriefs",
          icon: "no_encryption",
          link: "/debrief",
        },
      ];
      return menuItems;
    },
    itemsUser() {
      let menuItems = [
        {
          title: "Formulaire",
          icon: "no_encryption",
          link: "/",
        },
        {
          title: "Mes debriefs",
          icon: "no_encryption",
          link: "/debrief",
        },
      ];
      return menuItems;
    },

    userLogedIn() {
      return this.$store.getters.user;
    },

    userAuthorized() {
      return this.$store.getters.authorization;
    },

    getAvatarImg() {
      return this.$store.getters.avatar;
    }
  },
  methods: {
    logoutFromFirebase() {
      this.$store.dispatch("signOutAction");
    },
  },
};
</script>
